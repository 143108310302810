import Cookies from 'js-cookie'
import { LocalizationServiceV3StationData } from '@/lib/types/api/stations-data';

import {
  STATION_ID_COOKIE,
  STATION_CALLSIGN_COOKIE,
  STATION_COOKIE_AGE_DAYS,
  UNLOCALIZED_USER_COOKIE,
} from '@/lib/constants';

const setStationCookie = (station: LocalizationServiceV3StationData) => {
  const { station_id: stationId, callsign } = station;

  // RWEB-8832 if the UNLOCALIZED_USER_COOKIE exists, delete it
  if(Cookies.get(UNLOCALIZED_USER_COOKIE)) {
    Cookies.remove(UNLOCALIZED_USER_COOKIE, {
      domain: '.pbs.org',
      path: '/',
    });
  }

  Cookies.set(STATION_ID_COOKIE, stationId, {
    domain: '.pbs.org',
    path: '/',
    expires: STATION_COOKIE_AGE_DAYS,
    sameSite: 'none',
    secure: true,
  });

  Cookies.set(STATION_CALLSIGN_COOKIE, callsign, {
    domain: '.pbs.org',
    path: '/',
    expires: STATION_COOKIE_AGE_DAYS,
    sameSite: 'none',
    secure: true,
  })
}

export { setStationCookie };
