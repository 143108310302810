'use client';

// imports
import { useCallback, useEffect } from 'react';
import Link from 'next/link';
import { useAtom } from 'jotai';

// lib files
import { ORIGIN, INTERNALLY_NAVIGATING_KEY } from '@/lib/constants';
import { setRedirectCookie } from '@/lib/helpers/utils';
import { signinModalAtom, SignInModalStateEnum } from '@/lib/atoms/signin-modal';

// components
import Modal from '@/components/Modal/Modal';

// svgs
import AppleLogo from '/public/svg/apple.svg';
import FacebookLogo from '/public/svg/facebook-circle.svg';
import GoolgeLogo from '/public/svg/google.svg';
import PBSLogotype from '/public/svg/pbs-logotype-white--blue-fill-face.svg';

// styles
import styles from '@/components/SignInModal/SignInModal.module.scss';
import { useHasMounted } from '@/lib/hooks';
import { canAccessStorage } from '@/lib/helpers/is-storage-available';
export interface SignInModalProps {
  depIsOpen?: SignInModalStateEnum;
  onClose?: () => void;
}
function SignInModal(props: SignInModalProps) {
  const {
    depIsOpen,
    onClose
  } = props;
  const [isSigninModalOpen, setSigninModalOpen] = useAtom(signinModalAtom);
  const hasMounted = useHasMounted();
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    // if we have a showSignIn query param, open the modal
    if (params.get('showSignIn') === 'true') {
      setSigninModalOpen(SignInModalStateEnum.True);
      // remove only the showSignIn query param, and keep other params
      params.delete('showSignIn');
      const newParams = params.toString();
      const newUrl = window.location.pathname + (newParams ? '?' + newParams : '');
      window.history.replaceState({}, '', newUrl);
    }
  }, [isSigninModalOpen, setSigninModalOpen]);
  const showModal = depIsOpen && depIsOpen !== SignInModalStateEnum.False ? true : isSigninModalOpen !== SignInModalStateEnum.False;
  const showMyListBlurb = depIsOpen && depIsOpen === SignInModalStateEnum.TrueWithMyListBlurb ? true : isSigninModalOpen === SignInModalStateEnum.TrueWithMyListBlurb;
  const signInLink = useCallback((social?: string) => {
    if (!hasMounted) return;
    let url = `${ORIGIN}/sso/login`;
    if (process.env.NODE_ENV === 'development') {
      url += '?next_dev=true';
    }
    if (social) {
      if (url.includes('?')) {
        url += `&social=${social}`;
      } else {
        url += `?social=${social}`;
      }
    }
    return url;
  }, [hasMounted]);
  const registerLink = useCallback(() => {
    if (!hasMounted) return;
    return `${ORIGIN}/sso/register`;
  }, [hasMounted]);
  const handleLinkClick = () => {
    const params = new URLSearchParams(window.location.search);
    const returnURL = params.get('returnURL') || undefined;
    if (canAccessStorage('sessionStorage')) {
      sessionStorage.setItem(INTERNALLY_NAVIGATING_KEY, 'false');
    }
    setRedirectCookie(returnURL);
  };
  const handleModalClose = () => {
    if (onClose) {
      onClose();
    }
    setSigninModalOpen(SignInModalStateEnum.False);
  };
  return <Modal isOpen={showModal} className={styles.signin_modal} innerClassName={styles.signin_modal_inner} onClose={handleModalClose} data-sentry-element="Modal" data-sentry-component="SignInModal" data-sentry-source-file="SignInModal.tsx">
      <PBSLogotype className={styles.pbs_head_logo} data-sentry-element="PBSLogotype" data-sentry-source-file="SignInModal.tsx" />

      {showMyListBlurb && <p className={styles.my_list_blurb}>
          Sign in to add shows and videos to My List and save watch progress across any device.
        </p>}

      {/* Using <a> so that next doesn't append a trailing slash to these sso routes */}
      <a href={signInLink()} onClick={handleLinkClick} className={styles.email_btn}>
        Sign in with Email
      </a>

      <p className={styles.create_account}>
        New to PBS?<>&nbsp;</>
        {/* @TODO need to pass "activation" at whatever point we're doing passport activation in Next */}
        <a href={registerLink()} onClick={handleLinkClick}>
          Create an account
        </a>
      </p>
      <p className={styles.or}>or</p>

      {/* Using <a> so that next doesn't append a trailing slash to these sso routes */}
      <a href={signInLink('google')} onClick={handleLinkClick} className={styles.social_btn}>
        <GoolgeLogo className={styles.google_logo} data-sentry-element="GoolgeLogo" data-sentry-source-file="SignInModal.tsx" />
        Continue with Google
      </a>

      {/* Using <a> so that next doesn't append a trailing slash to these sso routes */}
      <a href={signInLink('facebook')} onClick={handleLinkClick} className={styles.social_btn}>
        <FacebookLogo className={styles.facebook_logo} data-sentry-element="FacebookLogo" data-sentry-source-file="SignInModal.tsx" />
        Continue with Facebook
      </a>

      {/* Using <a> so that next doesn't append a trailing slash to these sso routes */}
      <a href={signInLink('apple')} onClick={handleLinkClick} className={styles.social_btn}>
        <AppleLogo className={styles.apple_logo} data-sentry-element="AppleLogo" data-sentry-source-file="SignInModal.tsx" />
        Continue with Apple
      </a>

      <p className={styles.disclaimer}>
      By creating an account, you acknowledge that PBS may share your information with our member stations and our respective service providers, and that you have read and understand the<>&nbsp;</>
        <Link href="https://www.pbs.org/about/policies/privacy-policy/" target="_blank" rel="noopener noreferrer" data-sentry-element="Link" data-sentry-source-file="SignInModal.tsx">Privacy Policy</Link>
      <>&nbsp;</>and<>&nbsp;</>
        <Link href="https://www.pbs.org/about/policies/terms-of-use/" target="_blank" rel="noopener noreferrer" data-sentry-element="Link" data-sentry-source-file="SignInModal.tsx">Terms of Use</Link>.
      </p>
    </Modal>;
}
export default SignInModal;