import Cookies from "js-cookie";
import { REDIRECT_COOKIE } from "@/lib/constants";

// returns a list of states/territories for use in ChangeStationModal.tsx
export function getStateOptions() {
  return ([
    {value: "AL", name: "Alabama"},
    {value: "AK", name: "Alaska"},
    {value: "AS", name: "American Samoa"},
    {value: "AZ", name: "Arizona"},
    {value: "AR", name: "Arkansas"},
    {value: "CA", name: "California"},
    {value: "CO", name: "Colorado"},
    {value: "CT", name: "Connecticut"},
    {value: "DE", name: "Delaware"},
    {value: "DC", name: "District of Columbia"},
    {value: "FL", name: "Florida"},
    {value: "GA", name: "Georgia"},
    {value: "GU", name: "Guam"},
    {value: "HI", name: "Hawaii"},
    {value: "ID", name: "Idaho"},
    {value: "IL", name: "Illinois"},
    {value: "IN", name: "Indiana"},
    {value: "IA", name: "Iowa"},
    {value: "KS", name: "Kansas"},
    {value: "KY", name: "Kentucky"},
    {value: "LA", name: "Louisiana"},
    {value: "ME", name: "Maine"},
    {value: "MD", name: "Maryland"},
    {value: "MA", name: "Massachusetts"},
    {value: "MI", name: "Michigan"},
    {value: "MN", name: "Minnesota"},
    {value: "MS", name: "Mississippi"},
    {value: "MO", name: "Missouri"},
    {value: "MT", name: "Montana"},
    {value: "NE", name: "Nebraska"},
    {value: "NV", name: "Nevada"},
    {value: "NH", name: "New Hampshire"},
    {value: "NJ", name: "New Jersey"},
    {value: "NM", name: "New Mexico"},
    {value: "NY", name: "New York"},
    {value: "NC", name: "North Carolina"},
    {value: "ND", name: "North Dakota"},
    {value: "OH", name: "Ohio"},
    {value: "OK", name: "Oklahoma"},
    {value: "OR", name: "Oregon"},
    {value: "PA", name: "Pennsylvania"},
    {value: "PR", name: "Puerto Rico"},
    {value: "RI", name: "Rhode Island"},
    {value: "SC", name: "South Carolina"},
    {value: "SD", name: "South Dakota"},
    {value: "TN", name: "Tennessee"},
    {value: "TX", name: "Texas"},
    {value: "UT", name: "Utah"},
    {value: "VT", name: "Vermont"},
    {value: "VI", name: "Virgin Islands"},
    {value: "VA", name: "Virginia"},
    {value: "WA", name: "Washington"},
    {value: "WV", name: "West Virginia"},
    {value: "WI", name: "Wisconsin"},
    {value: "WY", name: "Wyoming"},
  ]);
}

export function setRedirectCookie(returnURL: string = window.location.href) {
  Cookies.set(REDIRECT_COOKIE, returnURL);
}
